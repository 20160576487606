<template>
    <com-form
        ref="form"
        label-width="120px"
        :col="2"
        :configs="formConfig"
    />
</template>

<script>
import { createTimeoutPromise } from "@utils/utils";

export default {
    data() {
        return {
            formConfig: async ({ formData, configData } = {}) => {
                console.log("formConfig handle", formData, configData);

                if (formData) {
                    formData = { ...formData };
                } else {
                    formData = {
                        toggle: "b"
                    };
                }

                let toggleItemList = null;

                if (formData.toggle === "b") {
                    toggleItemList = [
                        {
                            key: "b1",
                            label: "B选择日期",
                            type: "date"
                        },
                        {
                            key: "b2",
                            label: "B选择时间",
                            type: "time"
                        },
                        {
                            key: "b3",
                            label: "B选择日期时间",
                            type: "datetime"
                        },
                        {
                            type: "empty"
                        }
                    ];
                } else {
                    toggleItemList = [
                        {
                            key: "a1",
                            label: "A下拉多选",
                            type: "multiselect",
                            options: [
                                {
                                    key: "a1a",
                                    label: "a1a"
                                },
                                {
                                    key: "a1b",
                                    label: "a1b"
                                },
                                {
                                    key: "a1c",
                                    label: "a1c"
                                }
                            ],
                            default: () => []
                        },
                        {
                            key: "a2",
                            label: "A复选框",
                            type: "checkbox",
                            options: [
                                {
                                    key: "a2a",
                                    label: "a2a"
                                },
                                {
                                    key: "a2b",
                                    label: "a2b"
                                },
                                {
                                    key: "a2c",
                                    label: "a2c"
                                }
                            ],
                            fullLine: true,
                            default: () => []
                        },
                        {
                            key: "a3",
                            label: "A级联选择",
                            type: "cascader",
                            options: [
                                {
                                    value: "a",
                                    label: "a",
                                    children: [
                                        {
                                            value: "a1",
                                            label: "a1"
                                        },
                                        {
                                            value: "a2",
                                            label: "a2"
                                        }
                                    ]
                                },
                                {
                                    value: "b",
                                    label: "b",
                                    children: [
                                        {
                                            value: "b1",
                                            label: "b1"
                                        },
                                        {
                                            value: "b2",
                                            label: "b2"
                                        }
                                    ]
                                }
                            ],
                            default: () => []
                        },
                        {
                            type: "empty"
                        }
                    ];
                }

                configData = [
                    {
                        key: "name",
                        label: "要素编码",
                        placeholder: "请输入要素编码",
                        required: true
                    },
                    {
                        key: "toggle",
                        type: "radio",
                        label: "选填AB",
                        default: "a",
                        options: [
                            { key: "a", label: "A" },
                            { key: "b", label: "B" }
                        ],
                        fullLine: true
                    },
                    ...toggleItemList,
                    {
                        key: "type",
                        label: "要素类型",
                        type: "select",
                        options: [
                            { key: "a", label: "要素类型A" },
                            { key: "b", label: "要素类型B" },
                            { key: "c", label: "要素类型C" }
                        ],
                        placeholder: "请选择要素类型",
                        required: true
                    },
                    {
                        key: "fullLine",
                        label: "是否独占一行",
                        type: "select",
                        options: [
                            { key: "1", label: "否" },
                            { key: "0", label: "是" }
                        ],
                        default: "0"
                    },
                    {
                        key: "validateWay",
                        label: "校验方式",
                        type: "select",
                        options: [
                            { key: "0", label: "无" },
                            { key: "1", label: "时间" },
                            { key: "2", label: "时间日期" }
                        ]
                    },
                    {
                        key: "backup",
                        label: "备注",
                        type: "textarea",
                        fullLine: true
                    }
                ];

                configData.forEach(config => {
                    if (!config.key) {
                        return;
                    }
                    // eslint-disable-next-line no-undefined
                    if (formData[config.key] !== undefined) {
                        return;
                    }

                    if (typeof config.default === "function") {
                        formData[config.key] = config.default();
                        // eslint-disable-next-line no-undefined
                    } else if (config.default !== undefined) {
                        formData[config.key] = config.default;
                    } else {
                        formData[config.key] = null;
                    }
                });

                console.log("formConfig handle out", formData, configData);

                return {
                    formData,
                    configData
                };
            },
            editInitData: null
        };
    },
    methods: {
        cancel() {
            this.$confirm("确定取消编辑？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$emit("cancel");
            });
        },
        async submit() {
            let error = await new Promise(resolve => {
                this.$refs.form.validate((success, error) => {
                    console.log("success, error", success, error);

                    if (success) {
                        resolve();
                    } else {
                        resolve(error);
                    }
                });
            });

            if (error) {
                return;
            }

            this.$emit("submit", this.$refs.form.formData);

            return this.$refs.form.formData;
        },
        setFormData(data) {
            console.log("setFormData", data);

            this.editInitData = data;

            // this.$refs.form.formData = Object.assign({}, data);

            this.$refs.form.setFormData(Object.assign({}, data));
        }
    }
};
</script>
