import itemForm from "./blocks/itemForm";
import itemFormNormal from "./blocks/itemFormNormal";
import itemFormExperiment from "./blocks/itemFormExperiment";

import { createFullLoading, createTimeoutPromise } from "@utils/utils";

const initEditData = {
    name: "xxx",
    type: "b",
    fullLine: "1",
    validateWay: null,
    backup: "wulalala"
};

export default {
    name:"example",
    components: {
        itemForm,
        itemFormNormal,
        itemFormExperiment
    },
    data() {
        return {
            title: "高级模板要素配置",
            dialogRefNameList: [
                "itemFormDialog",
                "itemFormNormalDialog",
                "itemFormExperimentDialog"
            ],
            filterFormConfig: [
                {
                    key: "name",
                    label: "模板名称",
                    placeholder: "请输入模板名称"
                },
                {
                    key: "type",
                    label: "要素类型",
                    type: "select",
                    options: [
                        { key: "a", label: "要素类型A" },
                        { key: "b", label: "要素类型B" },
                        { key: "c", label: "要素类型C" }
                    ],
                    placeholder: "请选择要素类型"
                },
                {
                    key: "effectMake",
                    label: "有效标记",
                    type: "select",
                    options: async () => {
                        return await createTimeoutPromise([
                            { key: "a", label: "有效标记A" },
                            { key: "b", label: "有效标记B" },
                            { key: "c", label: "有效标记C" }
                        ]);
                    },
                    placeholder: "请选择有效标记"
                },
                {
                    key: "displayName",
                    label: "要素展示名称",
                    placeholder: "请输入要素展示名称"
                }
            ],
            topOperation: {
                left: [
                    {
                        name: "新增要素（简单表单）",
                        key: "add",
                        icon: "el-icon-plus",
                        type: "primary",
                        handle: () => {
                            console.log("add handle");
                            this.closeAllAndOpen("itemFormDialog");
                        }
                    },
                    {
                        name: "新增要素（自定义表单）",
                        key: "addNormal",
                        icon: "el-icon-plus",
                        type: "primary",
                        handle: () => {
                            console.log("add normal handle");

                            this.closeAllAndOpen("itemFormNormalDialog");
                        }
                    },
                    {
                        name: "新增要素（实验特性）",
                        key: "addExperiment",
                        icon: "el-icon-plus",
                        type: "primary",
                        handle: () => {
                            console.log("add experiment handle");

                            this.closeAllAndOpen("itemFormExperimentDialog");
                        }
                    },
                    {
                        name: "批量删除",
                        key: "batchDel",
                        requireArguments: ["batchSelected"],
                        handle: async batchSelected => {
                            console.log("batchDel", batchSelected);

                            if (!batchSelected || !batchSelected.length) {
                                this.$message({
                                    type: "error",
                                    message: "请勾选后操作"
                                });
                                return;
                            }

                            await this.$confirm(
                                `确认删除这${batchSelected.length}个吗？`
                            );

                            console.log("batchDel confirmed", batchSelected);
                        }
                    }
                ]
            },
            tableColumn: [
                { type: "select", width: 45 },
                { label: "服务请求类型", value: "key1" },
                { label: "受理时间", value: "key2" },
                { label: "商品名称", value: "key3" },
                { label: "货号", value: "key4" },
                { label: "工单状态", value: "key5" },
                {
                    label: "操作",
                    value: "op",
                    type: "render",
                    render: (h, { data }) => {
                        let { row } = data;

                        const modifyHandle = row => {
                                console.log("modifyHandle", row);
                                this.closeAllAndOpen("itemFormDialog");
                                this.$nextTick(() => {
                                    this.$refs.itemForm.setFormData(initEditData);
                                });
                            },
                            modifyNormalHandle = row => {
                                console.log("modifyNormalHandle", row);

                                this.closeAllAndOpen("itemFormNormalDialog");

                                this.$nextTick(() => {
                                    this.$refs.itemFormNormal.setFormData(
                                        initEditData
                                    );
                                });
                            },
                            modifyExperimentHandle = row => {
                                console.log("modifyExperimentHandle", row);

                                this.closeAllAndOpen("itemFormExperimentDialog");

                                this.$nextTick(() => {
                                    this.$refs.itemFormExperiment.setFormData(
                                        initEditData
                                    );
                                });
                            };

                        return (
                            <span>
                                <span
                                    class="blue-text-button"
                                    on-click={() => modifyHandle(row)}
                                >
                                    修改
                                </span>
                                &nbsp;&nbsp;
                                <span
                                    class="blue-text-button"
                                    on-click={() => modifyNormalHandle(row)}
                                >
                                    修改(自定义)
                                </span>
                                &nbsp;&nbsp;
                                <span
                                    class="blue-text-button"
                                    on-click={() => modifyExperimentHandle(row)}
                                >
                                    修改(实验)
                                </span>
                            </span>
                        );
                    }
                }
            ]
        };
    },
    methods: {
        f4() {
            this.$refs.filterTable.refetch();
        },
        closeAllAndOpen(dialogName) {
            this.dialogRefNameList.forEach(name => {
                name !== dialogName && this.$refs[name].close();
            });
            dialogName && this.$refs[dialogName].show();
        },
        async itemFormSubmitHandle(data) {
            console.log("itemFormSubmitHandle data", data);

            let loading = createFullLoading(this);

            await createTimeoutPromise();

            loading.close();

            this.closeAllAndOpen();
        }
    }
};
