<template>
    <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        label-width="120px"
        label-position="left"
        class="ofxh"
    >
        <el-row>
            <el-col class="p-h16" :span="12">
                <el-form-item prop="name" label="要素编码">
                    <el-input
                        v-model="formData.name"
                        placeholder="请输入要素编码"
                    ></el-input>
                </el-form-item>
            </el-col>

            <el-col class="p-h16" :span="12">
                <el-form-item prop="type" label="要素类型">
                    <el-select
                        v-model="formData.type"
                        class="w-100"
                        placeholder="请选择要素类型"
                    >
                        <el-option value="a" label="要素类型A" />
                        <el-option value="b" label="要素类型B" />
                        <el-option value="c" label="要素类型C" />
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col class="p-h16" :span="12">
                <el-form-item prop="fullLine" label="是否独占一行">
                    <el-select v-model="formData.fullLine" class="w-100">
                        <el-option value="1" label="否" />
                        <el-option value="0" label="是" />
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col class="p-h16" :span="12">
                <el-form-item prop="validateWay" label="校验方式">
                    <el-select v-model="formData.validateWay" class="w-100">
                        <el-option value="0" label="无" />
                        <el-option value="1" label="时间" />
                        <el-option value="2" label="时间日期" />
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col class="p-h16" :span="24">
                <el-form-item prop="backup" label="备注">
                    <el-input v-model="formData.backup" type="textarea" />
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
export default {
    data() {
        return {
            formData: {
                name: "",
                type: null,
                fullLine: "0",
                validateWay: null,
                backup: ""
            },
            rules: {
                name: {
                    required: true,
                    message: "请输入要素编码",
                    trigger: "blur"
                }
            },
            editInitData: null
        };
    },
    methods: {
        cancel() {
            this.$confirm("确定取消编辑？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$emit("cancel");
            });
        },
        async submit() {
            let error = await new Promise(resolve => {
                this.$refs.form.validate((success, error) => {
                    if (success) {
                        resolve();
                    } else {
                        resolve(error);
                    }
                });
            });

            if (error) {
                return;
            }

            this.$emit("submit", this.formData);

            return this.formData;
        },
        setFormData(data) {
            this.editInitData = data;

            this.formData = Object.assign({}, data);
        }
    }
};
</script>
