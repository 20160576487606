<template>
    <com-form
        ref="form"
        label-width="120px"
        :col="2"
        :configs="formConfig"
    />
</template>

<script>
import { createTimeoutPromise } from "@utils/utils";

export default {
    data() {
        return {
            formConfig: [
                {
                    key: "name",
                    label: "要素编码",
                    placeholder: "请输入要素编码",
                    required: true
                },
                {
                    key: "type",
                    label: "要素类型",
                    type: "select",
                    options: async () => {
                        return await createTimeoutPromise(
                            [
                                { key: "a", label: "要素类型A" },
                                { key: "b", label: "要素类型B" },
                                { key: "c", label: "要素类型C" }
                            ],
                            1000
                        );
                    },
                    placeholder: "请选择要素类型",
                    required: true
                },
                {
                    key: "fullLine",
                    label: "是否独占一行",
                    type: "select",
                    options: [
                        { key: "1", label: "否" },
                        { key: "0", label: "是" }
                    ],
                    default: "0"
                },
                {
                    key: "validateWay",
                    label: "校验方式",
                    type: "select",
                    options: [
                        { key: "0", label: "无" },
                        { key: "1", label: "时间" },
                        { key: "2", label: "时间日期" }
                    ]
                },
                {
                    key: "backup",
                    label: "备注",
                    type: "textarea",
                    fullLine: true
                }
            ],
            editInitData: null
        };
    },
    methods: {
        cancel() {
            this.$confirm("确定取消编辑？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$emit("cancel");
            });
        },
        async submit() {
            let error = await new Promise(resolve => {
                this.$refs.form.validate((success, error) => {
                    console.log("success, error", success, error);

                    if (success) {
                        resolve();
                    } else {
                        resolve(error);
                    }
                });
            });

            if (error) {
                return;
            }

            this.$emit("submit", this.$refs.form.formData);

            return this.$refs.form.formData;
        },
        setFormData(data) {
            this.editInitData = data;

            this.$refs.form.formData = Object.assign({}, data);
        }
    }
};
</script>
